import * as Styled from "./DataTable.styles";

import { CloseOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import { Divider, InputRef } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";

import DataTableSkeleton from "./DataTableSkeleton";
import Error from "components/Error/Error";
import NoData from "./NoData/NoData";
import Switches from "components/Switches/Switches";
import Tabs from "components/Tabs/Tabs";
import TopControls from "components/TopControls/TopControls";
import getFormattedDate from "utilities/getFormattedDate";
import { useLocation } from "context/LocationContext";

function DataTable() {
  const [editPartnerVenueId, setEditPartnerVenueId] = useState<boolean>(false);
  const [newPartnerVenueId, setNewPartnerVenueId] = useState<
    string | undefined
  >(undefined);

  const {
    locationActions,
    currentLocation,
    locationDetails,
    isLocationDetailsLoading,
    isLocationDetailsError,
    locationDetailsError,
    refetchLocationDetails,
    refetchLocationConfigs,
  } = useLocation();

  const inputRef = useRef<InputRef>(null);

  const handleRetry = useCallback(() => {
    void refetchLocationDetails?.();
    void refetchLocationConfigs?.();
  }, [refetchLocationConfigs, refetchLocationDetails]);

  const handleEditPartnerVenueId = useCallback(() => {
    if (editPartnerVenueId && inputRef.current?.input?.value) {
      setNewPartnerVenueId(undefined);

      inputRef.current.input.value = locationDetails?.partnerVenueId ?? "";
    }

    setEditPartnerVenueId(!editPartnerVenueId);
  }, [locationDetails?.partnerVenueId, editPartnerVenueId]);

  useEffect(() => {
    if (editPartnerVenueId && inputRef.current) {
      inputRef.current.focus({
        cursor: "end",
      });
    }
  }, [editPartnerVenueId]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewPartnerVenueId(event.currentTarget.value);
    },
    []
  );

  const handleSavePartnerVenueId = useCallback(() => {
    if (!newPartnerVenueId) {
      return console.error("Incomplete params to update the location");
    }

    locationActions.update({
      newPartnerVenueId,
    });
  }, [locationActions, newPartnerVenueId]);

  useEffect(() => {
    if (locationActions.updateSuccess && newPartnerVenueId) {
      setNewPartnerVenueId(undefined);
      setEditPartnerVenueId(false);
      handleRetry();
    }
  }, [handleRetry, locationActions.updateSuccess, newPartnerVenueId]);

  if (isLocationDetailsLoading) {
    return <DataTableSkeleton />;
  }

  if (isLocationDetailsError && locationDetailsError) {
    return (
      <Styled.Container>
        <Styled.ContentContainer>
          <Error
            title="Error fetching the Table Status"
            error={locationDetailsError}
            onRetry={handleRetry}
          />
        </Styled.ContentContainer>
      </Styled.Container>
    );
  }

  if (!locationDetails) {
    return (
      <Styled.Container>
        <Styled.ContentContainer>
          <NoData onRetry={handleRetry} />
        </Styled.ContentContainer>
      </Styled.Container>
    );
  }

  const { name, locationId, partnerVenueId, lastUpdate } = locationDetails;
  const lastSaved = lastUpdate
    ? getFormattedDate(lastUpdate, { timeStyle: "short" })
    : undefined;

  return (
    <Styled.Container>
      <Styled.ContentContainer>
        <Styled.InfoMessage
          className={!currentLocation?.enabled ? "show" : ""}
          type="info"
          message="Location Disabled."
          description="This location is currently disabled and the functionality is limited. To enable all functionalities, please enable this location."
          showIcon
        />
        <Styled.Header
          key={locationId}
          title={name}
          layout="vertical"
          bordered
          size="small"
          extra={<TopControls />}
        >
          <Styled.HeaderItem label="Last saved:">{lastSaved}</Styled.HeaderItem>
          <Styled.HeaderItem label="ID:">{locationId}</Styled.HeaderItem>
          <Styled.HeaderItem label="Partner Venue ID:">
            <Styled.ItemContainer>
              <Styled.Input
                key={`${locationId}_${name}`}
                disabled={!editPartnerVenueId}
                defaultValue={partnerVenueId}
                value={newPartnerVenueId ?? partnerVenueId}
                onChange={(event) => handleInputChange(event)}
                ref={inputRef}
              />
              <Styled.ButtonContainer>
                {editPartnerVenueId && (
                  <Styled.EditButton
                    disabled={
                      !newPartnerVenueId || locationActions.isUpdateLoading
                    }
                    loading={locationActions.isUpdateLoading}
                    onClick={handleSavePartnerVenueId}
                    type="default"
                    name="Save"
                    data-testid="savePartnerVenueId"
                  >
                    {!locationActions.isUpdateLoading && <SaveOutlined />}
                  </Styled.EditButton>
                )}
                <Styled.EditButton
                  disabled={locationActions.isUpdateLoading}
                  onClick={handleEditPartnerVenueId}
                  type="default"
                  name={!editPartnerVenueId ? "Edit" : "Close"}
                >
                  {!editPartnerVenueId ? <EditOutlined /> : <CloseOutlined />}
                </Styled.EditButton>
              </Styled.ButtonContainer>
            </Styled.ItemContainer>
          </Styled.HeaderItem>
        </Styled.Header>

        <Divider orientation="left">
          Enable / Disable Dashboard Services
        </Divider>
        <Switches />
        <Tabs />
      </Styled.ContentContainer>
    </Styled.Container>
  );
}

export default DataTable;
