import { Button, Collapse, Form } from "antd";

import { colors } from "theme/theme";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const Collapsible = styled(Collapse)`
  background-color: transparent;
  border-color: ${colors.borderDark};
  overflow: hidden;
  text-align: center;
  width: 100%;

  .ant-collapse-header {
    border-radius: 0 !important;
    font-weight: 600;
    padding: 4px 8px !important;

    .ant-collapse-header-text {
      color: ${colors.textLight};
    }

    .ant-collapse-expand-icon {
      svg {
        fill: ${colors.textLight};

        > * {
          fill: ${colors.textLight};
        }
      }
    }
  }

  .ant-collapse-content {
    border-radius: 0 !important;
    background-color: ${colors.backgroundDark} !important;
    border-color: ${colors.borderDark};
  }

  .ant-collapse-item {
    border-color: ${colors.borderDark};
  }
`;

export const AddFormItem = styled(Form.Item)`
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  button {
    width: 100%;
  }

  label {
    color: ${colors.textLight} !important;
    font-weight: 700;

    svg {
      fill: ${colors.textLight};
    }
  }

  input,
  .ant-select-selector {
    background-color: ${colors.siderBackground} !important;
    border-color: ${colors.borderDark} !important;
    color: ${colors.textLight};

    &::placeholder,
    .ant-select-selection-placeholder {
      color: ${colors.textGray};
    }
  }

  .ant-select-arrow {
    svg {
      fill: ${colors.textLight};
    }
  }
`;

export const AddButton = styled(Button)`
  &.overwrite {
    background-color: ${colors.background};
    color: ${colors.text};

    &:hover {
      background-color: ${colors.backgroundGray};
      border-color: transparent;
      color: ${colors.text};
    }
  }
`;
