import { Layout, Select } from "antd";

import { Partner } from "utilities/getPartners";
import { colors } from "theme/theme";
import { setTag } from "@sentry/react";
import { setTwoToneColor } from "@ant-design/icons";
import styled from "styled-components";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { usePartner } from "context/PartnerContext";

setTwoToneColor("#AEB8C2");

export const Container = styled(Layout)`
  align-items: center;
  background-color: ${colors.background};
  display: flex;
  gap: 8px;
  justify-content: center;
  margin: 24px;
  padding: 16px;
`;

function NoPartnerSelected() {
  const { partners } = usePartner();

  const navigate = useNavigate();

  const handleChange = useCallback(
    (e: string) => {
      navigate(`${e}`);
      setTag("partnerId", e);
    },
    [navigate]
  );

  const list = partners?.map((partner: Partner) => {
    return { value: partner.id, label: partner.name };
  });

  return (
    <Container>
      <h3>Select a Partner</h3>
      <Select
        style={{ width: 200 }}
        onChange={handleChange}
        options={list}
        size="large"
      />
    </Container>
  );
}

export default NoPartnerSelected;
