export const environment = {
  baseUrl: process.env.REACT_APP_API_URL,
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN,
    tracesSampleRate: process.env.REACT_APP_SENTRY_SAMPLERATE,
    release: process.env.REACT_APP_SENTRY_VERSION,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    replays: {
      sessionSampleRate:
        process.env.REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLERATE,
      onErrorSampleRate:
        process.env.REACT_APP_SENTRY_REPLAYS_ONERROR_SAMPLERATE,
    },
  },
  isDev: process.env.NODE_ENV === "development",
};

export const queryKeys = {
  checkMatch: "checkMatch",
  locations: "locations",
  locationDetails: "locationDetails",
  locationConfigs: "locationConfigs",
  partners: "partners",
  tableStatus: "tableStatus",
  lastOnboardingRequest: "lastOnboardingRequest",
  onboardingAuthUrl: "onboardingAuthUrl",
};
