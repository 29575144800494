import * as Styled from "./ErrorScreen.styles";

import { Button } from "antd";
import { useCallback } from "react";

interface ErrorScreenProps {
  title?: string;
  showReload: boolean;
}

function ErrorScreen({ title, showReload }: ErrorScreenProps) {
  const handleTryAgain = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <Styled.ErrorContainer
      status="error"
      title={title ?? "Something went wrong"}
      extra={
        showReload && [
          <Button onClick={handleTryAgain} type="primary" key="refresh">
            Try again
          </Button>,
        ]
      }
    />
  );
}

export default ErrorScreen;
