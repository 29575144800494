interface DateFormatOptions {
  locale?: string;
  dateStyle?: "full" | "long" | "medium" | "short";
  timeStyle?: "full" | "long" | "medium" | "short";
}

function getFormattedDate(
  dateString: string,
  options?: DateFormatOptions
): string {
  const date = new Date(dateString);

  const defaultOptions: DateFormatOptions = {
    locale: "en-US",
    dateStyle: "medium",
    timeStyle: "medium",
  };

  const mergedOptions = { ...defaultOptions, ...options };

  return new Intl.DateTimeFormat(mergedOptions.locale, {
    dateStyle: mergedOptions.dateStyle,
    timeStyle: mergedOptions.timeStyle,
  }).format(date);
}

export default getFormattedDate;
