import AppRoutes from "AppRoutes";
import LoginForm from "components/LoginForm/LoginForm";
import { useAuth } from "context/AuthContext";

function AuthenticationWrapper() {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? <AppRoutes /> : <LoginForm />;
}

export default AuthenticationWrapper;
