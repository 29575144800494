import { ApiClientProps } from "hooks/useApi";

interface GetCurrentLocationConfigProps {
  id?: number;
  partnerId?: string;
  posType?: string | null;
  token?: string;
  apiClient: ApiClientProps;
}

export interface LocationConfigsResponse {
  id: number | null;
  orderType: string | null;
  tableId: string | null;
  employeeId: string | null;
  revenueCenterId: string | null;
  paymentId: string | null;
  discountId: string | null;
  serviceChargeId: string | null;
  tenderTypeId: string | null;
}

async function getCurrentLocationConfig({
  id,
  partnerId,
  posType,
  token,
  apiClient,
}: GetCurrentLocationConfigProps): Promise<
  LocationConfigsResponse | undefined
> {

  if (!id || !posType || posType === "upserve" || posType === "lightspeed") {
    return;
  }

  if (!token) {
    throw new Error("No token provided for fetching location details");
  }

  try {
    const response = await apiClient.get({
      path: `/middleware-admin/partners/${partnerId}/pos/${posType}/locations/${id}/configs`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      noRetry: true,
    });

    if (!response) {
      return;
    }

    return response.resource;
  } catch (error) {
    throw new Error(
      "Error trying to fetch the location configs. Make sure this location has configs."
    );
  }
}

export default getCurrentLocationConfig;
