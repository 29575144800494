import * as Styled from "./CheckMatch.styles";

import {
  GetCheckMatchPropsResponse as CheckMatchDataProps,
  MenuGroup,
} from "utilities/getCheckMatch";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";

import CheckMatchMappingButtons from "components/CheckMatchMappingButtons/CheckMatchMappingButtons";
import isDifferent from "utilities/isDifferent";
import { useCheckMatch, CheckMatchInitDataProps } from "context/CheckMatchContext";
import { useParams } from "react-router-dom";

function CheckMatch() {
  const [save, setSave] = useState(false);
  const [initialCloseOfDayHour, setInitialCloseOfDayHour] = useState<number>(4);
  const [newCloseOfDayHour, setNewCloseOfDayHour] = useState<
    number | undefined
  >();
  const [initialTimeZone, setInitialTimeZone] =
    useState<string>("America/New_York");
  const [newTimeZone, setNewTimeZone] = useState<string | undefined>();

  const { posGUID } = useParams();

  const {
    cancelChanges,
    checkMatchData,
    initialMappingState,
    isCheckMatchMappingsSaving,
    isCheckMatchSaving,
    mappingState,
    updateCheckMatch,
    initCheckMatch,
    updateCheckMatchMappings,
    updateMappingState,
  } = useCheckMatch();

  const checkMatchMap = checkMatchData?.checkMatchMenuGroupMap ?? {};

  const checkMatchMapArray = Object.keys(checkMatchMap).map((key) => {
    const item = checkMatchMap[key];

    return {
      name: item.name,
      key,
      item,
    };
  });

  // reset new states when locationId changes
  useEffect(() => {
    if (posGUID) {
      setInitialCloseOfDayHour(4);
      setInitialTimeZone("America/New_York");
      setNewCloseOfDayHour(undefined);
      setNewTimeZone(undefined);
    }
  }, [posGUID]);

  useEffect(() => {
    if (checkMatchData?.closeOfDayHour) {
      setInitialCloseOfDayHour(checkMatchData?.closeOfDayHour);
    }
  }, [checkMatchData?.closeOfDayHour]);

  useEffect(() => {
    if (checkMatchData?.timeZone) {
      setInitialTimeZone(checkMatchData?.timeZone);
    }
  }, [checkMatchData?.timeZone]);

  useEffect(() => {
    const isMapDifferent =
      initialMappingState &&
      mappingState &&
      isDifferent(initialMappingState, mappingState);

    if (isMapDifferent) {
      setSave(true);
    } else {
      setSave(false);
    }
  }, [mappingState, initialMappingState]);

  const handleMapping = (menuGroupId: string, value: string) => {
    updateMappingState(menuGroupId, value);
  };

  const handleSave = useCallback(() => {
    updateCheckMatchMappings();
  }, [updateCheckMatchMappings]);

  const handleCancel = useCallback(() => {
    cancelChanges();
    setSave(false);
  }, [cancelChanges]);

  const handleCloseOfDayHourChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value) {
        setNewCloseOfDayHour(parseInt(e.target.value));
      }
    },
    []
  );

  const handleTimeZoneChange = useCallback((e: any) => {
    setNewTimeZone(e.toString());
  }, []);

  const handleSaveCheckMatchSettings = useCallback(() => {
    if (!checkMatchData) {
      const newCheckMatchData: CheckMatchInitDataProps = {
        closeOfDayHour: newCloseOfDayHour ?? initialCloseOfDayHour ?? 4,
        timeZone: newTimeZone ?? initialTimeZone,
      };
      initCheckMatch(newCheckMatchData);
      return;
    }

    const newCheckMatchData: CheckMatchDataProps = {
      ...checkMatchData,
      closeOfDayHour: newCloseOfDayHour ?? initialCloseOfDayHour ?? 4,
      timeZone: newTimeZone ?? initialTimeZone,
    };

    updateCheckMatch(newCheckMatchData);
  }, [
    checkMatchData,
    initialCloseOfDayHour,
    initialTimeZone,
    newCloseOfDayHour,
    newTimeZone,
    updateCheckMatch,
  ]);

  const columns = [
    {
      title: "Menu Group",
      dataIndex: "name",
      key: "name",
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: "Mapping",
      key: "item",
      dataIndex: "item",
      render: (item: MenuGroup) => {
        return (
          <CheckMatchMappingButtons
            item={item}
            mappingState={mappingState}
            onHandleMapping={handleMapping}
          />
        );
      },
    },
  ];

  return (
    <>
      <Styled.ButtonsRow>
        <Styled.FieldContainer>
          <Styled.Label htmlFor="closeOfDayHour">
            Close of Day Hour:
          </Styled.Label>
          <Styled.Input
            id="closeOfDayHour"
            value={newCloseOfDayHour ?? initialCloseOfDayHour}
            onChange={handleCloseOfDayHourChange}
            disabled={isCheckMatchSaving}
          />
        </Styled.FieldContainer>

        <Styled.FieldContainer>
          <Styled.Label htmlFor="timeZone">Time Zone: </Styled.Label>
          <Styled.Select
            id="timeZone"
            value={newTimeZone ?? initialTimeZone}
            style={{ width: 200 }}
            onChange={handleTimeZoneChange}
            disabled={isCheckMatchSaving}
          >
            <Styled.Select.Option value="America/New_York">
              Eastern Time
            </Styled.Select.Option>
            <Styled.Select.Option value="America/Chicago">
              Central Time
            </Styled.Select.Option>
            <Styled.Select.Option value="America/Denver">
              Mountain Time
            </Styled.Select.Option>
            <Styled.Select.Option value="America/Los_Angeles">
              Pacific Time
            </Styled.Select.Option>
            <Styled.Select.Option value="America/Anchorage">
              Alaska Time
            </Styled.Select.Option>
            <Styled.Select.Option value="Pacific/Honolulu">
              Hawaii Time
            </Styled.Select.Option>
          </Styled.Select>
        </Styled.FieldContainer>

        <Styled.Button
          type="default"
          loading={isCheckMatchSaving}
          onClick={handleSaveCheckMatchSettings}
          className="circular-btn"
        >
          {!isCheckMatchSaving && <SaveOutlined />}
        </Styled.Button>

        <Styled.Divider type="vertical" />

        <Styled.Button
          disabled={!save || isCheckMatchMappingsSaving}
          loading={isCheckMatchMappingsSaving}
          onClick={handleSave}
          type="primary"
          icon={<SaveOutlined />}
        >
          Save Mapping Changes
        </Styled.Button>

        <Styled.Button
          disabled={!save || isCheckMatchMappingsSaving}
          onClick={handleCancel}
          type="default"
          icon={<CloseOutlined />}
        >
          Cancel
        </Styled.Button>
      </Styled.ButtonsRow>

      <Styled.Table
        columns={columns}
        dataSource={checkMatchMapArray}
        pagination={{ hideOnSinglePage: true }}
        style={{ marginBottom: "32px" }}
      />
    </>
  );
}

export default CheckMatch;
