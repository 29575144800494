import * as Styled from "./Error.styles";

import { Button } from "antd";

interface ErrorProps {
  title: string;
  error: Error;
  onRetry?: () => void;
}

function Error({ error, onRetry, title }: ErrorProps) {
  return (
    <Styled.Container>
      <Styled.Error
        status="error"
        title={title}
        subTitle={error.name}
        extra={
          onRetry && [
            <Button key="retry" type="primary" onClick={onRetry}>
              Retry
            </Button>,
          ]
        }
      />
    </Styled.Container>
  );
}

export default Error;
