import { Alert, Skeleton } from "antd";

import { Link as RouterLink } from "react-router-dom";
import { colors } from "theme/theme";
import styled from "styled-components";

export const LocationsList = styled.ul`
  list-style: none;
  margin: 16px 0 0;
  overflow-y: scroll;
  padding: 0px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 170px);
`;

export const Locations = styled.li`
  align-items: center;
  border-bottom: 1px solid ${colors.borderDark};

  &:last-child {
    border-bottom: none;
  }
`;

export const Link = styled(RouterLink)`
  align-items: center;
  border-left: 4px solid transparent;
  color: ${colors.textLight};
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  padding: 12px 16px;
  width: 100%;

  &:hover {
    background-color: ${colors.siderBackgroundLighter};
    color: ${colors.textLight};
  }

  &.active {
    border-left-color: ${colors.secondary};
    font-weight: 600;
  }
`;

export const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 16px;

  .ant-skeleton-input {
    background-color: ${colors.borderDark} !important;
  }
`;

export const SkeletonButton = styled(Skeleton.Button)`
  padding-left: 16px;

  .ant-skeleton-button {
    background-color: ${colors.borderDark} !important;
  }
`;

export const LocationsError = styled(Alert)`
  margin-left: 16px;
`;
