import { ApiClientProps } from "hooks/useApi";

export interface MenuGroupProps {
  menuGroupId: string;
  name: string;
  tableStatus: number;
  lastUpdate: string;
}

export interface LocationDetailsProps {
  checkMatchEnabled: boolean;
  enabled: boolean;
  id: number;
  lastUpdate: string | null | undefined;
  locationId: string;
  logWebhook: boolean;
  name: string | null;
  partnerKey: string | null;
  partnerVenueId: string;
  posType: string | null;
  skipSend: boolean;
  tableStatusEnabled: boolean;
  tableStatusMap:
    | {
        [menuGroupId: string]: MenuGroupProps;
      }
    | null
    | undefined;
}

interface GetLocationDetailsProps {
  id?: number;
  partnerId?: string;
  posType?: string | null;
  token?: string;
  apiClient: ApiClientProps;
}

async function getLocationDetails({
  id,
  partnerId,
  posType,
  token,
  apiClient,
}: GetLocationDetailsProps): Promise<LocationDetailsProps | null> {
  if (!id || !posType) {
    return null;
  }

  if (!token) {
    throw new Error("No token provided for fetching location details");
  }

  try {
    const response = await apiClient.get({
      path: `/middleware-admin/partners/${partnerId}/pos/${posType}/locations/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      noRetry: true,
    });

    return response.resource;
  } catch (error) {
    throw new Error(
      "Network request error trying to fetch the location details"
    );
  }
}

export default getLocationDetails;
