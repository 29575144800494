import { ApiClientProps } from "hooks/useApi";

export interface GetLastOnboardingCallResponse {
  requestId: string | null;
  createdAt: string;
  posType: string | null;
  onboardingStatusRequests: OnboardingStatusRequest[] | null;
  requestBody: string | null;
}

interface OnboardingStatusRequest {
  status: string | null;
  createdAt: string | null;
}

interface GetLastCompleteOnboardingRequestProps {
  locationId: string | undefined;
  partnerId: string | undefined;
  token: string | undefined;
  apiClient: ApiClientProps;
}

async function getLastCompleteOnboardingRequest({
  locationId,
  partnerId,
  token,
  apiClient,
}: GetLastCompleteOnboardingRequestProps): Promise<GetLastOnboardingCallResponse | undefined> {
  if (!locationId || !partnerId) {
    throw new Error(
      "Network failure. Please check your connection and ensure your request includes all required parameters."
    );
  }

  if (!token) {
    throw new Error("No token provided for fetching check match");
  }
  
  const path = `/middleware-admin/partners/${partnerId}/locations/onboarding/last-request/${locationId}`;

  try {
    const response = await apiClient.get({
      path,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      noRetry: true,
    });

    if (!response) {
      return;
    }

    return response.resource;
  } catch (error) {
    throw new Error(error as string);
  }
}

export default getLastCompleteOnboardingRequest;
