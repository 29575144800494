import { ReactElement, createContext, useCallback, useContext } from "react";

import { message } from "antd";

type NotificationType = "success" | "error" | "info" | "warning";

export interface NotificationProps {
  type: NotificationType;
  message: string;
}

interface NotificationContextProps {
  notify: (notification: NotificationProps) => Promise<void>;
}

export const NotificationContext =
  createContext<NotificationContextProps | null>(null);

NotificationContext.displayName = "NotificationContext";

export function NotificationProvider(props: any): ReactElement {
  const notify = useCallback(
    async ({ type, message: content }: NotificationProps) => {
      await message[type](
        content,
        type === "success" ? 3 : type === "warning" ? 5 : 10
      );
    },
    []
  );

  const value: NotificationContextProps = { notify };

  return (
    <NotificationContext.Provider value={value} {...props} />
  ) as ReactElement;
}

export function useNotifications(): NotificationContextProps {
  const context = useContext(NotificationContext);

  if (context === undefined) {
    throw new Error(
      "useNotifications must be used within a NotificationProvider"
    );
  }

  // Because we are getting the context from TableStatusProvider here, if context is
  // not 'undefined' (ie: the TableStatusProvider is not a parent of this component),
  // we can be sure context is not 'null' here
  if (context === null) {
    throw new Error("NotificationProvider supplied null context");
  }

  return context;
}
