import { ApiClientProps } from "hooks/useApi";

export interface GetOnboardingAuthUrlResponse {
  authUrl: string;
  message: string;
}

interface GetOnboardingAuthUrl {
  locationId: string | undefined;
  partnerId: string | undefined;
  token: string | undefined;
  apiClient: ApiClientProps;
}


async function getOnboardingAuthUrl({
  locationId,
  partnerId,
  token,
  apiClient,
}: GetOnboardingAuthUrl): Promise<GetOnboardingAuthUrlResponse | undefined> {
  if (!locationId || !partnerId) {
    throw new Error(
      "Network failure. Please check your connection and ensure your request includes all required parameters."
    );
  }

  if (!token) {
    throw new Error("No token provided for fetching check match");
  }
  
  const path = `/middleware-admin/partners/${partnerId}/locations/${locationId}/onboarding/lightspeed/auth/url`;

  try {
    const response = await apiClient.get({
      path,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      noRetry: true,
    });

    if (!response) {
      return;
    }

    return response.resource;
  } catch (error) {
    throw new Error(error as string);
  }
}

export default getOnboardingAuthUrl;
