import { ApiClientProps } from "hooks/useApi";
import { GetCheckMatchPropsResponse as checkMatchDataProps } from "./getCheckMatch";

export interface CheckMatchMenuGroupMapProps {
  [menuGroupId: string]: {
    menuGroupId: string | null;
    value: string | null;
  };
}

interface GetLocationsProps {
  checkMatchData?: checkMatchDataProps;
  newMapping: CheckMatchMenuGroupMapProps;
  partnerId?: string;
  token?: string;
  apiClient: ApiClientProps;
}

async function updateCheckMatch({
  checkMatchData,
  newMapping,
  partnerId,
  token,
  apiClient,
}: GetLocationsProps): Promise<void> {
  if (!checkMatchData || !newMapping || !partnerId || !token) {
    throw new Error(
      "Network failure. Please check your connection and ensure your request includes all required parameters."
    );
  }

  if (!token) {
    throw new Error("No token provided for updating check match");
  }

  const { locationId } = checkMatchData;

  const path = `/middleware-admin/partners/${partnerId}/check-match/mappings/${locationId}`;

  const body = JSON.stringify({
    ...checkMatchData,
    checkMatchMenuGroupMap: newMapping,
  });

  try {
    const response = await apiClient.put({
      path,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body,
      noRetry: true,
    });

    return response.resource;
  } catch (error) {
    throw new Error(error as string);
  }
}

export default updateCheckMatch;
