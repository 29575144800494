import * as Styled from "./LoginForm.styles";

import { Button, Input } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import React, { useCallback, useEffect, useState } from "react";

import { useAuth } from "context/AuthContext";

const LoginForm: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const { signIn, error } = useAuth();

  const handleOnFinish = useCallback(
    (values: any) => {
      setLoading(true);

      const { username, password } = values;

      if (username && password && signIn) {
        void signIn(username, password);
      }
    },
    [signIn]
  );

  useEffect(() => {
    if (error === "signIn-error") {
      setLoading(false);
    }
  }, [error]);

  return (
    <Styled.FormContainer>
      <Styled.Form
        name="login-form"
        initialValues={{ remember: true }}
        onFinish={handleOnFinish}
      >
        {error && (
          <Styled.ErrorMessage
            description="There was an error signing in. Please try again."
            type="error"
            showIcon
          />
        )}
        <Styled.FormTitle>Sign in</Styled.FormTitle>
        <Styled.FormItem
          name="username"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please input your email!",
            },
          ]}
        >
          <Input prefix={<MailOutlined />} placeholder="Email" />
        </Styled.FormItem>
        <Styled.FormItem
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            type="password"
            placeholder="Password"
          />
        </Styled.FormItem>
        <Styled.FormItem>
          <Button type="primary" htmlType="submit" loading={loading}>
            Sign in
          </Button>
        </Styled.FormItem>
      </Styled.Form>
    </Styled.FormContainer>
  );
};

export default LoginForm;
