import {
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { addBreadcrumb } from "@sentry/react";
import apiClient from "utilities/api/apiClient";
import isTokenExpired from "utilities/isTokenExpired";

interface User {
  username: string;
}

interface AuthContextProps {
  user: User | null;
  isAuthenticated: boolean;
  signIn?: (username: string, password: string) => Promise<void>;
  signOut?: () => void;
  token?: string;
  error?: string;
  validateAuthentication: () => void;
}

export const AuthContext = createContext<AuthContextProps | null>(null);

AuthContext.displayName = "AuthContext";

export const AuthProvider = (props: any): ReactElement => {
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [token, setToken] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);

  // TO-DO: Improve autToken management in FE when auth flow improves in the BE
  useEffect(() => {
    const storedToken = localStorage.getItem("authToken");

    if (storedToken) {
      setIsAuthenticated(!isTokenExpired(storedToken));
      setToken(storedToken);
    }
  }, []);

  const handleToken = useCallback((_token: string) => {
    localStorage.setItem("authToken", _token);
    setToken(_token);
    setIsAuthenticated(true);
  }, []);

  const signIn = useCallback(
    async (user: string, password: string) => {
      setError(undefined);

      const body = JSON.stringify({ user, password });

      addBreadcrumb({
        level: "info",
        category: "user_action",
        message: "User Sign In",
      });

      const response = await apiClient
        .post({
          path: "/middleware-admin/login",
          headers: {
            "Content-Type": "application/json",
          },
          body,
          noRetry: true,
        })
        .catch(() => {
          setError("signIn-error");
        });

      if (response.token) {
        const { token: responseToken } = response;
        handleToken(responseToken);
        setError(undefined);
      } else {
        throw new Error("Login Failed");
      }
    },
    [handleToken]
  );

  const signOut = useCallback(() => {
    localStorage.removeItem("authToken");
    setUser(null);
    setIsAuthenticated(false);
    setToken(undefined);
  }, []);

  const validateAuthentication = useCallback(() => {
    setIsAuthenticated(token ? !isTokenExpired(token) : true);
  }, [token]);

  const authContextValue: AuthContextProps = useMemo(
    () => ({
      user,
      isAuthenticated,
      signIn,
      signOut,
      token,
      error,
      validateAuthentication,
    }),
    [
      error,
      isAuthenticated,
      signIn,
      signOut,
      token,
      user,
      validateAuthentication,
    ]
  );

  return (
    <AuthContext.Provider value={authContextValue} {...props} />
  ) as ReactElement;
};

export function useAuth(): AuthContextProps {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }

  if (context === null) {
    throw new Error("AuthProvider supplied null context");
  }

  return context;
}
