import { Card as AntCard } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap 16px;
  margin-bottom: 32px;
`;

export const Card = styled(AntCard)`
  .ant-card-body {
    display: flex;
    gap: 8px;
    padding: 12px;
  }
`;
