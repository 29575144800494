import * as Sentry from "@sentry/react";

import React, { useEffect } from "react";
import {
  captureConsoleIntegration,
  extraErrorDataIntegration,
} from "@sentry/integrations";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import App from "./App";
import { BrowserTracing } from "@sentry/browser";
import ReactDOM from "react-dom/client";
import { environment } from "utilities/constants";
import { isTruthy } from "utilities/isTruthy";
import reportWebVitals from "./reportWebVitals";
import sentryUniqueId from "utilities/sentryUniqueId";

Sentry.init({
  enabled: !environment.isDev,
  debug: false,
  dsn: environment.sentry.dsn,
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: [
        "localhost",
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        new URL(environment.baseUrl!).host,
        /^\//,
      ],
      idleTimeout: 60000,
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    extraErrorDataIntegration({ depth: 10 }),
    captureConsoleIntegration({ levels: ["warn", "error"] }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: Number(environment.sentry.tracesSampleRate),
  environment: environment.sentry.environment,
  release: environment.sentry.release,
  normalizeDepth: 11,
  replaysSessionSampleRate: Number(
    environment.sentry.replays.sessionSampleRate
  ),
  replaysOnErrorSampleRate: Number(
    environment.sentry.replays.onErrorSampleRate
  ),
  beforeSend(event, hint) {
    let message = event.message;

    if (
      event.logger === "console" &&
      event.message?.includes("[object Object]")
    ) {
      const objects = event.extra?.arguments as any[];
      message = objects
        ?.map((value) =>
          typeof value === "object" ? JSON.stringify(value) : value
        )
        .join(" | ");
    }

    message = `${message} | Current Path ${window.location.pathname}`;

    if (isTruthy(window.location.search)) {
      message = `${message}?${window.location.search}`;
    }

    event.message = message;
    console.debug("Sentry Event", event, hint);
    return event;
  },
  beforeSendTransaction(event, hint) {
    console.debug("Sentry Transaction", event, hint);
    return event;
  },
});

const scope = Sentry.getCurrentScope();
scope.setTag("unique_id", sentryUniqueId.uniqueId());
scope.setTag("transaction_id", sentryUniqueId.transactionId());
scope.setTag("transaction_origin", sentryUniqueId.transactionEntryPoint());
if (sentryUniqueId.transactionEntryPointParams()) {
  scope.setTag(
    "transaction_origin_params",
    sentryUniqueId.transactionEntryPointParams()
  );
  scope.setTag(
    "transaction_name",
    `${sentryUniqueId.transactionEntryPoint()} - TXN ${sentryUniqueId.transactionId()}`
  );
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
