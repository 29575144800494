import { ApiClientProps } from "hooks/useApi";
import { LocationDetailsProps } from "./getLocationDetails";

interface UpdateTableStatusEnabledProps {
  tableStatusEnabled: boolean;
  locationDetails: LocationDetailsProps;
  partnerId?: string;
  token?: string;
  apiClient: ApiClientProps;
}

async function updateTableStatusEnabled({
  tableStatusEnabled,
  locationDetails,
  partnerId,
  token,
  apiClient,
}: UpdateTableStatusEnabledProps) {
  if (!locationDetails || !partnerId) {
    throw new Error(
      "Network failure. Please check your connection and ensure your request includes a valid locationDetails parameter."
    );
  }

  if (!token) {
    throw new Error("No token provided for fetching check match enabled");
  }

  const updatedLocationDetails = {
    ...locationDetails,
    tableStatusEnabled,
  };

  const body = JSON.stringify(updatedLocationDetails);

  try {
    const response = await apiClient.put({
      path: `/middleware-admin/partners/${partnerId}/locations/${locationDetails.id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body,
      noRetry: true,
    });

    return response;
  } catch (error) {
    throw new Error(error as string);
  }
}

export default updateTableStatusEnabled;
