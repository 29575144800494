function isDifferent(obj1: any, obj2: any): boolean {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return true;
  }

  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && isDifferent(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return true;
    }
  }

  return false;
}

function isObject(object: any) {
  return object != null && typeof object === "object";
}

export default isDifferent;
