import * as Styled from "./LocationConfig.styles";

import { Button, Empty, Form, Input } from "antd";
import { useEffect, useMemo, useState } from "react";

import { useLocation } from "context/LocationContext";
import { useParams } from "react-router-dom";

interface ToastConfigProps {
  id: number;
  orderType: string;
  tableId: string;
  employeeId: string;
  revenueCenterId: string;
  paymentId: string;
  discountId: string;
  serviceChargeId: string;
}

interface OmnivoreConfigProps {
  id: number;
  orderType: string;
  tableId: string;
  employeeId: string;
  revenueCenterId: string;
  tenderTypeId: string;
  discountId: string;
  serviceChargeId: string;
}

interface UpserveConfigProps {
  id: number;
  orderType: string;
  tableId: string;
  employeeId: string;
  revenueCenterId: string;
}

export type ConfigValues =
  | ToastConfigProps
  | OmnivoreConfigProps
  | UpserveConfigProps;

type PosTypeKey = "omnivore" | "upserve" | "toast";

function LocationConfig() {
  const [hasChanged, setHasChanged] = useState<boolean>(false);

  const { currentLocation, locationActions, isLoadingConfigs, configs } =
    useLocation();

  const { partnerId } = useParams();

  const [form] = Form.useForm();

  const locationConfigs = useMemo(() => {
    return (
      configs ?? {
        id: "",
        orderType: "",
        tableId: "",
        employeeId: "",
        revenueCenterId: "",
        paymentId: "",
        discountId: "",
        serviceChargeId: "",
        tenderTypeId: "",
      }
    );
  }, [configs]);

  const posTypeFields: Record<PosTypeKey, string[]> = {
    toast: [
      "id",
      "orderType",
      "tableId",
      "employeeId",
      "revenueCenterId",
      "paymentId",
      "discountId",
      "serviceChargeId",
    ],
    omnivore: [
      "id",
      "orderType",
      "tableId",
      "employeeId",
      "revenueCenterId",
      "tenderTypeId",
      "discountId",
      "serviceChargeId",
    ],
    upserve: ["id", "orderType", "tableId", "employeeId", "revenueCenterId"],
  };

  const posType: PosTypeKey =
    (currentLocation?.posType as PosTypeKey) ?? "toast";

  const fieldsToShow: string[] = posTypeFields[posType];

  // Initialize form with the configs data
  useEffect(() => {
    form.setFieldsValue(locationConfigs);
  }, [locationConfigs, form]);

  const handleOnValuesChange = (_: any, allValues: ConfigValues) => {
    if (locationConfigs) {
      const isDifferent = (
        Object.keys(locationConfigs) as Array<keyof ConfigValues>
      ).some((key) => allValues[key] !== locationConfigs[key]);

      setHasChanged(isDifferent);
    }
  };

  const handleOnFinish = (values: ConfigValues) => {
    if (!hasChanged) {
      console.info("No changes to submit");
      return null;
    }

    if (!currentLocation) {
      console.error("Missing current location");
      return null;
    }

    locationActions.updateConfigs({
      id: currentLocation?.id,
      locationId: currentLocation?.locationId,
      name: currentLocation?.name,
      partnerId,
      posType: currentLocation?.posType,
      configs: values,
    });
  };

  const handleOnReset = () => {
    setHasChanged(false);
  };

  if (isLoadingConfigs) {
    return <span>Loading...</span>;
  }

  return (
    <Styled.Container>
      <h4>Location Configuration</h4>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleOnFinish}
        onValuesChange={handleOnValuesChange}
        onReset={handleOnReset}
      >
        {fieldsToShow?.map((field: string) => {
          if (field === "id") {
            return null;
          }

          return (
            <Form.Item key={field} name={field} label={field}>
              <Input />
            </Form.Item>
          );
        })}

        <Form.Item className="button-row">
          <Button disabled={!hasChanged} type="primary" htmlType="submit">
            Save Changes
          </Button>

          <Button disabled={!hasChanged} type="default" htmlType="reset">
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Styled.Container>
  );
}

export default LocationConfig;
