import { Radio as AntRadio } from "antd";
import styled from "styled-components";

export const Radio = styled(AntRadio.Button)`
  pointer-events: initial;

  input[type="radio"] {
    pointer-events: initial;
  }
`;
