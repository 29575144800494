import { Alert, Input as AntInput, Button, Descriptions, Layout } from "antd";

import { colors } from "theme/theme";
import styled from "styled-components";

const { Content } = Layout;

export const Container = styled(Layout)`
  padding: 0 24px 24px;
  margin: 24px 0;
`;

export const ContentContainer = styled(Content)`
  background: ${colors.background};
  border-radius: 16px;
  overflow: hidden;
  padding: 16px;
`;

export const Header = styled(Descriptions)`
  margin-bottom: 32px;

  .ant-descriptions-title {
    font-size: 22px;
  }

  .ant-descriptions-item-label {
    font-weight: 600;
  }
`;

export const HeaderItem = styled(Descriptions.Item)``;

export const ItemContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`;

export const EditButton = styled(Button)`
  border-radius: 50%;
  overflow: hidden;
  padding: 4px 8px;
`;

export const Input = styled(AntInput)``;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;
  justify-content: end;
`;

export const SkeletonRow = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
`;

export const InfoMessage = styled(Alert)`
  opacity: 0;
  margin-bottom: 0;
  padding: 0;
  height: 0px;

  .ant-alert-message {
    font-weight: 600;
  }

  &.show {
    opacity: 1;
    height: auto;
    margin-bottom: 16px;
    padding: 20px 24px;
    transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  }
`;
