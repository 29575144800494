import * as Styled from "./Switches.styles";

import { Switch } from "antd";
import { useCheckMatch } from "context/CheckMatchContext";
import { useTableStatus } from "context/TableStatusContext";

function Switches() {
  const { handleUpdateTableStatusEnabled, isTableStatusEnabled } =
    useTableStatus();

  const { handleUpdateCheckMatchEnabled, updateCheckMatchMappingsEnabled, isCheckMatchEnabled } =
    useCheckMatch();

  const handleTableStatusChange = (checked: boolean) => {
    handleUpdateTableStatusEnabled(checked);
  };

  const handleCheckMatchEnabled = (checked: boolean) => {
    handleUpdateCheckMatchEnabled(checked);
    updateCheckMatchMappingsEnabled(checked);
  };

  return (
    <Styled.Container>
      <Styled.Card>
        Table Status
        <Switch
          id="table-status"
          checked={isTableStatusEnabled}
          onChange={handleTableStatusChange}
        />
      </Styled.Card>
      <Styled.Card>
        Check Match
        <Switch
          id="check-match"
          checked={isCheckMatchEnabled}
          onChange={handleCheckMatchEnabled}
        />
      </Styled.Card>
    </Styled.Container>
  );
}

export default Switches;
