import { jwtDecode } from "jwt-decode";

function isTokenExpired(token: string) {
  try {
    const decoded: { exp: number } = jwtDecode(token);
    const currentUnixTime = Math.floor(Date.now() / 1000);
    return currentUnixTime > decoded.exp;
  } catch (error) {
    console.error("AuthToken Expired: ", error);
    return true;
  }
}

export default isTokenExpired;
