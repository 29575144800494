import { Result } from "antd";
import styled from "styled-components";

export const ErrorContainer = styled(Result)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;

  button {
    &:hover {
      cursor: pointer;
    }
  }
`;
