import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 10px;
  }
  
  body {
    margin: 0;
  }

  #root, #root * {
    font-family: 'Manrope', sans-serif;
  }

  .ant-message-notice-content {
    max-width: 580px;
    width: calc(100% - 32px);
  }
  
  .ant-message-custom-content {
    align-items: start;
    display: flex;
    text-align: left;
    padding-top: 5px;
  }

  .ant-message-custom-content > span:not(.anticon) {
    margin-top: -5px;
  }
`;

export default GlobalStyle;
