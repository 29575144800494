import { ApiClientProps } from "hooks/useApi";

export interface MenuGroup {
  menuGroupId: string;
  menuGroupPosId: string | null;
  name: string;
  value: string | null;
  lastUpdate: string;
}

export interface GetCheckMatchPropsResponse {
  enabled: boolean | null;
  partnerVenueId: string | null;
  partnerVenueName: string | null;
  locationId: string | null;
  posType: string | null;
  closeOfDayHour: number | null;
  timeZone: string | null;
  checkMatchMenuGroupMap: { [key: string]: MenuGroup };
}

interface GetLocationsProps {
  id?: number | undefined;
  locationId: string | undefined;
  partnerId: string | undefined;
  posType?: string | undefined;
  token: string | undefined;
  apiClient: ApiClientProps;
}

async function getCheckMatch({
  id,
  locationId,
  partnerId,
  posType,
  token,
  apiClient,
}: GetLocationsProps): Promise<GetCheckMatchPropsResponse | undefined> {
  if (!locationId || !id || !posType) {
    throw new Error(
      "Network failure. Please check your connection and ensure your request includes all required parameters."
    );
  }

  if (!token) {
    throw new Error("No token provided for fetching check match");
  }

  const urlLocationId = posType === "upserve" ? id : locationId;

  const path = `/middleware-admin/partners/${partnerId}/pos/${posType}/check-match/mappings/${urlLocationId}`;

  try {
    const response = await apiClient.get({
      path,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      noRetry: true,
    });

    return response.resource;
  } catch (error) {
    throw new Error(error as string);
  }
}

export default getCheckMatch;
