import { Table as AntTable } from "antd";
import styled from "styled-components";

export const ButtonsRow = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  margin-bottom: 16px;
`;

export const Table = styled(AntTable)`
  margin-bottom: 32px;
`;
