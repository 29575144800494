import { ApiClientProps } from "hooks/useApi";

export interface UpdateLocationConfigProps {
  id: number;
  locationId: string;
  name: string | null;
  posType: string;
  configs: {
    orderType: string | null | undefined;
    tableId: string | null | undefined;
    employeeId: string | null | undefined;
    revenueCenterId: string | null | undefined;
    paymentId?: string | null | undefined;
    discountId?: string | null | undefined;
    serviceChargeId?: string | null | undefined;
    tenderTypeId?: string | null | undefined;
  };
}

interface SuccessResponse {
  hasErrors: false;
  message: string;
  resourceId: number;
}

interface ErrorResponse {
  status: string;
  timestamp: string;
  message: string;
  debugMessage: string | null;
  validationErrors: any | null;
}

type Response = SuccessResponse | ErrorResponse;

async function updateLocationConfig(
  { id, locationId, name, posType, configs }: UpdateLocationConfigProps,
  apiClient: ApiClientProps,
  partnerId?: string,
  token?: string
): Promise<Response> {
  if (!locationId || !name || !posType || !id) {
    throw new Error(
      "Network failure. Please check your connection and ensure your request includes all required parameters."
    );
  }

  if (!token) {
    throw new Error("No token provided for creating the location");
  }

  if (!partnerId) {
    throw new Error("No partnerId provided for creating the location");
  }

  const body = JSON.stringify({
    ...configs,
  });

  return await apiClient.put({
    path: `/middleware-admin/partners/${partnerId}/pos/${posType}/locations/${id}/configs`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body,
  });
}

export default updateLocationConfig;
