import * as Styled from "./Locations.styles";

import AddLocationForm from "components/AddLocationForm/AddLocationForm";
import LocationFilter from "components/LocationFilter/LocationFilter";
import LocationsSkeleton from "./LocationsSkeleton";
import { RightOutlined } from "@ant-design/icons";
import { useLocation } from "context/LocationContext";

function Locations() {
  const {
    currentLocation,
    locations,
    isLocationsError,
    locationsError,
    partnerId,
  } = useLocation();

  if (isLocationsError) {
    return (
      <Styled.LocationsError
        type="error"
        message="Error loading locations:"
        description={locationsError?.message}
        showIcon
      />
    );
  }

  if (!locations) {
    return <LocationsSkeleton />;
  }

  return (
    <>
      <AddLocationForm />
      <LocationFilter />
      <Styled.LocationsList>
        {locations.map((location, index) => {
          const isActive = currentLocation?.locationId === location.locationId;
          return (
            <Styled.Locations key={`location-${index}`}>
              <Styled.Link
                className={isActive ? "active" : ""}
                to={`${partnerId}/${location.locationId}`}
              >
                <span>{location.name ?? "No name location"}</span>
                {isActive && <RightOutlined />}
              </Styled.Link>
            </Styled.Locations>
          );
        })}
      </Styled.LocationsList>
    </>
  );
}

export default Locations;
