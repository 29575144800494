import {
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import getPartners, { Partner } from "utilities/getPartners";

import { queryKeys } from "utilities/constants";
import useApi from "hooks/useApi";
import { useAuth } from "./AuthContext";
import { useNotifications } from "./NotificationsContext";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

export interface PartnerContextProps {
  partners?: Partner[] | null;
  initialPartners?: Partner[] | null;
  currentPartner?: Partner | null;
  isError: boolean;
  isLoading: boolean;
  error: Error | null;
  setPartners: React.Dispatch<
    React.SetStateAction<Partner[] | null | undefined>
  >;
}

export const PartnerContext = createContext<PartnerContextProps | null>(null);

PartnerContext.displayName = "PartnerContext";

export function PartnerProvider(props: any): ReactElement {
  const [partners, setPartners] = useState<Partner[] | null | undefined>(
    undefined
  );
  const [currentPartner, setCurrentPartner] = useState<
    Partner | null | undefined
  >();

  const [isFetchError, setIsFetchError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const { token } = useAuth();

  const { partnerId } = useParams();

  const { notify } = useNotifications();

  const apiClient = useApi();

  const {
    data: initialPartners,
    isLoading,
    isError,
    error,
    // refetch: refetchPartners,
  } = useQuery<Partner[] | undefined, Error>(
    queryKeys.partners,
    async () => await getPartners(token, apiClient),
    {
      onError: (error: any) => {
        if (error?.message) {
          setErrorMessage(error.message);
          setIsFetchError(true);
        }
      },
    }
  );

  useEffect(() => {
    if (initialPartners && !partners) {
      setPartners(initialPartners);
    }
  }, [initialPartners, partners]);

  useEffect(() => {
    if (partnerId !== currentPartner?.id) {
      setCurrentPartner(
        partners?.find((partner) => partner.id === parseInt(partnerId ?? ""))
      );
    }
  }, [currentPartner?.id, partnerId, partners]);

  useEffect(() => {
    if (isFetchError && errorMessage) {
      const notification = async () => {
        await notify({
          type: "error",
          message: errorMessage,
        });
      };

      void notification();

      setIsFetchError(false);
    }
  }, [errorMessage, isFetchError, notify]);

  const value: PartnerContextProps = {
    partners,
    initialPartners,
    currentPartner,
    isLoading,
    isError,
    error,
    setPartners,
  };
  return (<PartnerContext.Provider value={value} {...props} />) as ReactElement;
}

export function usePartner(): PartnerContextProps {
  const context = useContext(PartnerContext);

  if (context === undefined) {
    throw new Error("usePartner must be used within a PartnerProvider");
  }

  // Because we are getting the context from PartnerProvider here, if context is
  // not 'undefined' (ie: the PartnerProvider is not a parent of this component),
  // we can be sure context is not 'null' here
  if (context === null) {
    throw new Error("PartnerProvider supplied null context");
  }

  return context;
}
