import * as Antd from "antd";

import styled from "styled-components";

export const ButtonsRow = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  margin-bottom: 16px;
`;

export const Table = styled(Antd.Table)`
  margin-bottom: 32px;
`;

export const Button = styled(Antd.Button)`
  &.circular-btn {
    border-radius: 50%;
    overflow: hidden;
    padding: 4px 8px;
    height: 32px;
    width: 32px;
  }
`;

export const Divider = styled(Antd.Divider)`
  display: flex;
`;

export const FieldContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  gap: 8px;
`;

export const Label = styled.label``;

export const Input = styled(Antd.Input)`
  flex: 1;
`;

export const Select = styled(Antd.Select)`
  flex: 1;
`;
