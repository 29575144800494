import { Input } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  align-items: center;
  display: flex;
  border-radius: initial;
  flex-direction: column;
  font-weight: 600;
  gap: 8px;
  margin: 16px 0 0;
  padding: 0;
  width: 100%;
`;

const { Search } = Input;

export const LocationSearch = styled(Search)`
  flex: 1;
  width: 100%;
`;
