import * as Styled from "./LocationFilter.style";

import { useCallback } from "react";
import { useLocation } from "context/LocationContext";

function LocationFilter() {
  const { initialLocations, setLocations, locations } = useLocation();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value;

      if ((!value || value.length === 0) && initialLocations) {
        setLocations(initialLocations);
      } else {
        setLocations(
          initialLocations?.filter(
            (location) =>
              // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
              location.name?.toLowerCase().includes(value.toLowerCase()) ||
              location.partnerVenueId?.includes(value.toString()) ||
              location.locationId?.includes(value.toString())
          )
        );
      }
    },
    [initialLocations, setLocations]
  );

  return (
    <Styled.Container>
      <Styled.LocationSearch
        allowClear={true}
        placeholder="Search by Location or Venue ID"
        style={{ width: "100%" }}
        onChange={handleChange}
      />
      {locations?.length === 0 && <span>Non-existing location:</span>}
    </Styled.Container>
  );
}

export default LocationFilter;
