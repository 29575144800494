import { Result } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  max-height: calc(100vh - 136px);
`;

export const Error = styled(Result)`
  align-self: center;
  width: 100%;
`;
