import { DatabaseTwoTone, setTwoToneColor } from "@ant-design/icons";

import { Layout } from "antd";
import { colors } from "theme/theme";
import styled from "styled-components";

setTwoToneColor("#AEB8C2");

export const Container = styled(Layout)`
  align-items: center;
  background-color: ${colors.background};
  display: flex;
  gap: 8px;
  justify-content: center;
  margin: 24px;
  padding: 16px;

  .anticon {
    font-size: 64px;
  }
`;

function NoLocationSelected() {
  return (
    <Container>
      <DatabaseTwoTone />
      No Location Selected
    </Container>
  );
}

export default NoLocationSelected;
