import { ApiClientProps } from "hooks/useApi";

export interface Partner {
  id: number;
  key: string;
  name: string;
}

async function getPartners(
  token: string | undefined,
  apiClient: ApiClientProps
): Promise<Partner[] | undefined> {
  if (!token) {
    throw new Error("No token provided for fetching locations");
  }

  try {
    const response = await apiClient.get({
      path: "/middleware-admin/partners",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.resource;
  } catch (error) {
    throw new Error(error as string);
  }
}

export default getPartners;
