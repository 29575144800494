import { ApiClientProps } from "hooks/useApi";
import { LocationDetailsProps } from "./getLocationDetails";

export interface Location {
  id: number;
  enabled: boolean;
  lastUpdate?: string;
  locationId: string;
  name: string | null;
  partnerKey: string;
  partnerVenueId: string;
  posType: string;
  tableStatusMap?: LocationDetailsProps["tableStatusMap"];
}

interface GetLocationsProps {
  token: string | undefined;
  partnerId: string | undefined;
  apiClient: ApiClientProps;
}

async function getLocations({
  token,
  partnerId,
  apiClient,
}: GetLocationsProps): Promise<Location[] | undefined> {
  if (!token) {
    throw new Error("No token provided for fetching locations");
  }

  try {
    const response = await apiClient.get({
      path: `/middleware-admin/partners/${partnerId}/locations`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.resource;
  } catch (error) {
    throw new Error("Network request error trying to fetch the locations");
  }
}

export default getLocations;
