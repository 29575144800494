import { geekblue, volcano } from "@ant-design/colors";

export const colors = {
  background: "#ffffff",
  backgroundGray: "#F4F5F7",
  backgroundDark: "#262626",
  border: "#dee2e6",
  borderDark: "#262626",
  primary: volcano[2],
  primaryLight: volcano[0],
  secondary: geekblue[5],
  siderBackground: "#141414",
  siderBackgroundLighter: "#1f1f1f",
  text: "#000000d8",
  textDark: "#000000",
  textLight: "#ffffff",
  textGray: "#8c8c8c",
};
