import * as Styled from "./CheckMatchMappingButtons.styles";

import { Radio, RadioChangeEvent } from "antd";

import { CheckMatchMenuGroupMapProps } from "utilities/updateCheckMatch";
import { MenuGroup } from "utilities/getCheckMatch";
import { useCallback } from "react";

interface MappingButtonsProps {
  item: MenuGroup;
  mappingState: CheckMatchMenuGroupMapProps | null | undefined;
  onHandleMapping: (menuGroupId: string, value: string) => void;
}

interface CheckMatchValuesProps {
  [key: string]: string;
}

const checkMatchValues: CheckMatchValuesProps = {
  Beverage: "beverage",
  Entree: "entree",
  "No Map": "no map",
};

const checkMatchMapping = ["Beverage", "Entree", "No Map"];

function CheckMatchMappingButtons({
  item,
  mappingState,
  onHandleMapping,
}: MappingButtonsProps) {
  const { menuGroupId, value: itemValue } = item;

  const handleOnChange = useCallback(
    ({ target: { value } }: RadioChangeEvent) => {
      onHandleMapping(menuGroupId, value);
    },
    [menuGroupId, onHandleMapping]
  );

  if (!mappingState) {
    return null;
  }

  return (
    <Radio.Group
      onChange={handleOnChange}
      name={menuGroupId}
      value={mappingState[menuGroupId]?.value ?? itemValue ?? null}
      buttonStyle="solid"
    >
      {checkMatchMapping.map((value, index: number) => {
        return (
          <Styled.Radio
            key={`groupItem-${index}`}
            value={checkMatchValues[value]}
            data-testid={`${menuGroupId}-${value}`}
          >
            {value}
          </Styled.Radio>
        );
      })}
    </Radio.Group>
  );
}

export default CheckMatchMappingButtons;
