import { colors } from "theme/theme";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 20px;

  h4 {
    margin: 0;
  }

  form {
    border-left: 1px solid ${colors.border};
    flex: 1;
    padding-left: 20px;

    label {
      font-weight: 600;
    }

    .button-row {
      :where(.css-dev-only-do-not-override-nbd1lu).ant-form-item
        .ant-form-item-control-input-content {
        display: flex;
        gap: 20px;
      }
    }
  }
`;
