import { QueryClient, QueryClientProvider } from "react-query";

import { AuthProvider } from "context/AuthContext";
import AuthenticationWrapper from "components/AuthenticationWrapper/AuthenticationWrapper";
import { ConfigProvider } from "antd";
import { ErrorBoundary } from "@sentry/react";
import ErrorScreen from "components/ErrorScreen/ErrorScreen";
import GlobalStyle from "theme/GlobalStyles";
import { NotificationProvider } from "context/NotificationsContext";
import ProviderTreeBuilder from "context/ProviderTreeBuilder";
import { ReactQueryDevtools } from "react-query/devtools";
import { colors } from "theme/theme";
import { geekblue } from "@ant-design/colors";

const queryClient = new QueryClient();

const ProviderTree = ProviderTreeBuilder([
  [QueryClientProvider, { client: queryClient }],
  [AuthProvider],
  [NotificationProvider],
]);

function App() {
  return (
    <ErrorBoundary fallback={<ErrorScreen showReload />} showDialog>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: geekblue[6],
            colorText: colors.text,
          },
        }}
      >
        <GlobalStyle />
        <ProviderTree>
          <AuthenticationWrapper />
          <ReactQueryDevtools />
        </ProviderTree>
      </ConfigProvider>
    </ErrorBoundary>
  );
}

export default App;
