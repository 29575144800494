import * as Styled from "./Layout.styles";

import { CheckMatchProvider } from "context/CheckMatchContext";
import { LocationProvider } from "context/LocationContext";
import { Outlet } from "react-router-dom";
import { PartnerProvider } from "context/PartnerContext";
import ProviderTreeBuilder from "context/ProviderTreeBuilder";
import Sidebar from "components/Sidebar/Sidebar";
import { TableStatusProvider } from "context/TableStatusContext";

const ProviderTree = ProviderTreeBuilder([
  [PartnerProvider],
  [LocationProvider],
  [TableStatusProvider],
  [CheckMatchProvider],
]);

function Layout() {
  return (
    <ProviderTree>
      <Styled.Container>
        <Styled.InnerContainer>
          <Sidebar />
          <Outlet />
        </Styled.InnerContainer>
      </Styled.Container>
    </ProviderTree>
  );
}

export default Layout;
