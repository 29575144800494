import { useCallback, useEffect, useState } from "react";

import { Tabs as AntTabs } from "antd";
import CheckMatch from "components/CheckMatch/CheckMatch";
import LocationConfig from "components/LocationConfig/LocationConfig";
import TableStatus from "components/TableStatus/TableStatus";
import type { TabsProps } from "antd";
import { useLocation } from "context/LocationContext";
import { useSearchParams } from "react-router-dom";
import { useTableStatus } from "context/TableStatusContext";

const ACTIVE_TAB_PARAM: string = "activeTab";

function Tabs() {
  const [activeTab, setActiveTab] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();

  const { isTableStatusEnabled } = useTableStatus();

  const { currentLocation, locationDetails } = useLocation();

  const isCheckMatchEnabled = locationDetails?.checkMatchEnabled;

  const isUpserveLocation = currentLocation?.posType === "upserve";

  const isLightspeedLocation = currentLocation?.posType === "lightspeed";

  useEffect(() => {
    const param = searchParams.get(ACTIVE_TAB_PARAM);

    if (!param) {
      const tab = isTableStatusEnabled ? "tableStatus" : 
        isCheckMatchEnabled ? "checkMatch" : 
          "locationConfig";

      setSearchParams(`${ACTIVE_TAB_PARAM}=${tab}`);
    } else {
      setActiveTab(param);
    }
  }, [
    isCheckMatchEnabled,
    isTableStatusEnabled,
    searchParams,
    setSearchParams,
  ]);

  const handleActiveTab = useCallback(
    (tab: string) => {
      setSearchParams(`${ACTIVE_TAB_PARAM}=${tab}`);
      setActiveTab(tab);
    },
    [setSearchParams]
  );

  const items: TabsProps["items"] = [
    {
      key: "tableStatus",
      label: "Table Status",
      children: <TableStatus />,
      // disabled: !isTableStatusEnabled,
    },
    {
      key: "checkMatch",
      label: "Check Match",
      children: <CheckMatch />,
    },
    {
      key: "locationConfig",
      label: "Location Config",
      children: <LocationConfig />,
      disabled: isUpserveLocation || isLightspeedLocation,
    },
  ];

  return (
    <AntTabs activeKey={activeTab} items={items} onTabClick={handleActiveTab} />
  );
}

export default Tabs;
