import { ApiClientProps } from "hooks/useApi";
import { GetTableStatusPropsResponse as tableStatusDataProps } from "./getTableStatus";

export interface TableStatusMenuGroupMapProps {
  [menuGroupId: string]: {
    menuGroupId: string | null;
    tableStatus: number | null;
  };
}

interface GetLocationsProps {
  tableStatusData?: tableStatusDataProps;
  newMapping: TableStatusMenuGroupMapProps;
  partnerId?: string;
  token?: string;
  apiClient: ApiClientProps;
}

async function updateTableStatus({
  tableStatusData,
  newMapping,
  partnerId,
  token,
  apiClient,
}: GetLocationsProps): Promise<void> {
  if (!tableStatusData || !newMapping || !partnerId) {
    throw new Error(
      "Network failure. Please check your connection and ensure your request includes all required parameters."
    );
  }

  if (!token) {
    throw new Error("No token provided for updating tableStatus");
  }

  const path = `/middleware-admin/v1/partners/${partnerId}/status`;

  const body = JSON.stringify({
    ...tableStatusData,
    tableStatusMap: newMapping,
  });

  try {
    const response = await apiClient.put({
      path,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body,
      noRetry: true,
    });

    return response.resource;
  } catch (error) {
    throw new Error(error as string);
  }
}

export default updateTableStatus;
