export const getItem = (key: string): string | undefined => {
  const value = document.cookie
    .split("; ")
    .reduce((previousCookie, currentCookie) => {
      const item = currentCookie.split("=");
      const storedKey = item[0];
      const storedValue = item[1];
      return key === storedKey
        ? decodeURIComponent(storedValue)
        : previousCookie;
    }, "");

  return value.length > 0 ? value : undefined;
};

export const setItem = (key: string, value?: any, numberOfDays?: number) => {
  const now = new Date();
  let cookieValue = `${key}=${value ?? ""}`;

  if (numberOfDays) {
    now.setTime(now.getTime() + numberOfDays * 60 * 60 * 24 * 1000);
    cookieValue += `; expires=${now.toUTCString()}; path=/`;
  }

  if (!value) {
    const location = window.location;
    const domain =
      location.hostname.split(".").length > 1
        ? location.hostname.slice(location.host.indexOf(".", 0) + 1)
        : location.hostname;

    const now = new Date(0).toUTCString();
    cookieValue += `; expires=${now}; path=/; domain=${domain}; max-age=0`;
  }

  document.cookie = cookieValue;
};
