import * as Styled from "./Locations.styles";

import { Skeleton } from "antd";

function LocationsSkeleton() {
  return (
    <div data-testid="locations-skeleton">
      <Styled.SkeletonButton block active />
      <Styled.SkeletonContainer>
        {new Array(6).fill(null).map((_, index) => {
          const key = index + 1;
          return <Skeleton.Input key={key} block active />;
        })}
      </Styled.SkeletonContainer>
    </div>
  );
}

export default LocationsSkeleton;
