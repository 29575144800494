import * as Styled from "./NoData.styles";

import { Button, Result } from "antd";

import { DatabaseTwoTone } from "@ant-design/icons";

interface NoDataProps {
  onRetry?: () => void;
}

function NoData({ onRetry }: NoDataProps) {
  return (
    <Styled.Container>
      <Result
        title="There's no data available"
        icon={<DatabaseTwoTone />}
        extra={
          onRetry && [
            <Button key="retry" type="primary" onClick={onRetry}>
              Retry
            </Button>,
          ]
        }
      />
    </Styled.Container>
  );
}

export default NoData;
