import * as Styled from "./TableStatusMappingButtons.styles";

import { Radio, RadioChangeEvent } from "antd";

import { MenuGroupProps } from "utilities/getLocationDetails";
import { TableStatusMenuGroupMapProps } from "utilities/updateTableStatus";
import { useCallback } from "react";

interface MappingButtonsProps {
  item: MenuGroupProps;
  mappingState: TableStatusMenuGroupMapProps | null | undefined;
  onHandleMapping: (menuGroupId: string, tableStatus: number) => void;
}

interface TableStatusValuesProps {
  [key: string]: number;
}

const tableStatusValues: TableStatusValuesProps = {
  Entree: 11,
  Appetizer: 14,
  Dessert: 12,
  "No Map": 0,
};

const tableStatusMapping = ["Appetizer", "Entree", "Dessert", "No Map"];

function TableStatusMappingButtons({
  item,
  mappingState,
  onHandleMapping,
}: MappingButtonsProps) {
  const { menuGroupId, tableStatus } = item;

  const handleOnChange = useCallback(
    ({ target: { value } }: RadioChangeEvent) => {
      onHandleMapping(menuGroupId, value);
    },
    [menuGroupId, onHandleMapping]
  );

  if (!mappingState) {
    return null;
  }

  return (
    <Radio.Group
      onChange={handleOnChange}
      name={menuGroupId}
      value={mappingState[menuGroupId]?.tableStatus ?? tableStatus ?? 0}
      buttonStyle="solid"
    >
      {tableStatusMapping.map((status, index: number) => {
        return (
          <Styled.Radio
            key={`groupItem-${index}`}
            value={tableStatusValues[status]}
          >
            {status}
          </Styled.Radio>
        );
      })}
    </Radio.Group>
  );
}

export default TableStatusMappingButtons;
