import * as Styled from "./Sidebar.styles";

import { DownOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import Locations from "components/Locations/Locations";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { usePartner } from "context/PartnerContext";

function Sidebar() {
  const { partners, currentPartner } = usePartner();
  const navigate = useNavigate();

  const handleSelectedPartner = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, id: number) => {
      event.preventDefault();

      const partnerURL = `/${id}`;
      navigate(partnerURL);
    },
    [navigate]
  );

  const items = partners?.map((partner) => ({
    key: partner.key,
    label: (
      <a href="/#" onClick={(e) => handleSelectedPartner(e, partner.id)}>
        {partner.name}
      </a>
    ),
  }));

  return (
    <Styled.Aside className="overwrite" width={300}>
      <Styled.Title>
        {currentPartner?.name ?? "Rooam"} Dashboard
        <Dropdown menu={{ items }} trigger={["click"]}>
          <Styled.Button id="partner-select-icon">
            <DownOutlined />
          </Styled.Button>
        </Dropdown>
      </Styled.Title>
      <Locations />
    </Styled.Aside>
  );
}

export default Sidebar;
