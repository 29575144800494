import styled from "styled-components";

export const Container = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
  max-height: calc(100vh - 136px);
`;
