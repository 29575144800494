import * as Styled from "./DataTable.styles";

import { Divider, Skeleton } from "antd";

function DataTableSkeleton() {
  return (
    <Styled.Container>
      <Styled.ContentContainer>
        <Styled.Header
          title={<Skeleton.Input />}
          layout="horizontal"
          bordered
          size="small"
          extra={
            <Styled.SkeletonRow>
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Styled.SkeletonRow>
          }
        >
          <Styled.HeaderItem label="">
            <Skeleton.Input />
          </Styled.HeaderItem>
          <Styled.HeaderItem label="">
            <Skeleton.Input />
          </Styled.HeaderItem>
        </Styled.Header>
        <Skeleton.Input block />
        <Divider />
        <Styled.SkeletonRow>
          <Skeleton.Input />
          <Skeleton.Input />
          <Skeleton.Input />
        </Styled.SkeletonRow>
        <Skeleton />
      </Styled.ContentContainer>
    </Styled.Container>
  );
}

export default DataTableSkeleton;
