import { Layout } from "antd";
import { colors } from "theme/theme";
import styled from "styled-components";

const { Sider } = Layout;

export const Container = styled(Layout)``;

export const InnerContainer = styled(Layout)`
  min-height: calc(100vh);

  .ant-layout {
    border-radius: 16px;
  }
`;

export const Aside = styled(Sider)`
  &.overwrite {
    background-color: ${colors.siderBackground};
    color: ${colors.textLight};
    padding: 16px 16px 0;
  }
`;

export const Title = styled.h1`
  font-size: 2rem;
  font-weight: 600;
  margin-top: 0;
  padding: 0;

  .pill {
    background-color: ${colors.textLight};
    border-radius: 6px;
    color: ${colors.background};
    margin-right: 8px;
    padding: 2px 6px;
    font-weight: 800;
  }

  #partner-select-icon {
    color: ${colors.textLight};
    margin: 0 4px;

    .anticon-down {
      font-size: 16px;
    }
  }
`;
