import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import DataTable from "components/DataTable/DataTable";
import Layout from "components/Layout/Layout";
import NoLocationSelected from "components/NoLocationSelected/NoLocationSelected";
import NoPartnerSelected from "components/NoPartnerSelected/NoPartnerSelected";
import { wrapCreateBrowserRouter } from "@sentry/react";

function AppRoutes() {
  const router = sentryCreateBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Layout />}>
        <Route index path="/" element={<NoPartnerSelected />} />
        <Route path=":partnerId">
          <Route index element={<NoLocationSelected />} />
          <Route path=":posGUID" element={<DataTable />} />
        </Route>
      </Route>
    )
  );

  return <RouterProvider router={router} />;
}

export default AppRoutes;

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);
