import { Alert, Form as AntdForm } from "antd";

import { colors } from "theme/theme";
import styled from "styled-components";

export const FormContainer = styled.div`
  align-items: center;
  background-color: ${colors.backgroundGray};
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
`;

export const Form = styled(AntdForm)`
  background-color: ${colors.background};
  border: solid 1px ${colors.border};
  border-radius: 16px;
  max-width: 320px;
  padding: 32px 32px 8px;
`;

export const FormItem = styled(Form.Item)`
  .ant-input-prefix {
    margin-inline-end: 8px;
  }
`;

export const FormTitle = styled.h3`
  margin-top: 0;
`;

export const ErrorMessage = styled(Alert)`
  margin-bottom: 18px;
  padding-inline: 14px;
  padding-block: 13px;
`;
