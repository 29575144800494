import * as AntDivider from "antd";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 16px;
`;

export const SwitchContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`;

export const Divider = styled(AntDivider.Divider)`
  height: 32px;
`;

export const Button = styled(AntDivider.Button)`
  &.circular-btn {
    border-radius: 50%;
    overflow: hidden;
    padding: 4px 8px;
    height: 32px;
    width: 32px;
  }
`;

export const Modal = styled(AntDivider.Modal)`
`;

export const Paragraph = styled(AntDivider.Typography.Paragraph)`
`;